import { createWebHistory, createRouter } from 'vue-router';
import adminRoutes from './AdminRoutes';
import authRoutes from './authRoutes';
import { getItem } from '../utility/localStorageControl';
import { meta, setMeta } from "./meta";

// Define the base URL dynamically
const baseHistory = createWebHistory(process.env.BASE_URL || '/'); // Use BASE_URL or a fallback

const routes = [
  {
    name: 'Admin',
    path: '/',
    component: () => import(/* webpackChunkName: "admin" */ '@/layout/withAdminLayout.vue'),
    children: [...adminRoutes],
    meta: {
      ...meta['admin']
    }
  },
  {
    name: 'Auth',
    path: '/auth',
    component: () => import(/* webpackChunkName: "auth" */ '@/layout/withAuthLayout.vue'),
    children: [...authRoutes],
    meta: {
      ...meta['login']
    }
  },
  {
    path: '/:catchAll(.*)', // Catch-all route to handle 404
    component: () => import(/* webpackChunkName: "auth" */ '@/layout/withAuthLayout.vue'),
    children: [
      {
        path: '',
        name: 'NotFound',
        component: () => import(/* webpackChunkName: "Blank" */ '@/view/ErrorPage/404.vue')
      },
    ],
    meta: {
      ...meta['error-404']
    }
  },
  {
    path: '/error-401', // Catch-all route to handle 404
    component: () => import(/* webpackChunkName: "auth" */ '@/layout/withAuthLayout.vue'),
    children: [
      {
        path: '/error-401',
        name: 'PagePermission',
        component: () => import(/* webpackChunkName: "Blank" */ '@/view/ErrorPage/401.vue')
      }
    ],
    meta: {
      ...meta['error-401']
    }
  },
];

// Redirect pages
const isAuth = [
  "login",
  "register",
  "forgotPassword",
  "update-password",
]

// Add names of routes that require login
const isLogin = [
  {name: 'dashboard',               module: 'dashboard'},
  {name: 'quotations-products',     module: 'View Generated Quotations'},
  {name: 'quotations-reports',      module: 'View Generated Quotations'},
  {name: 'inquiries',               module: 'View Inquiries'},
  {name: 'activity-logs',           module: 'View Activity Logs'},
  {name: 'error-logs',              module: 'View Error Logs'},
  {name: 'all-users',              module: 'View list users'},
  {name: 'archive-all-users',      module: 'Restore users'},
  {name: 'all-users-create',       module: 'Add users'},
  {name: 'all-users-update',       module: 'View Edit single users'},
  {name: 'all-users-view',         module: 'View Edit single users'},
  {name: 'roles-permissions',       module: 'View list roles'},
  {name: 'view-role-permissions',   module: 'Users'},
  {name: 'create-role-permissions', module: 'Add roles'},
  {name: 'update-role-permissions', module: 'View Edit single roles'},
  {name: 'all-products',           module: 'View list products'},
  {name: 'archive-all-products',   module: 'Restore products'},
  {name: 'all-products-create',    module: 'Add products'},
  {name: 'all-products-update',    module: 'View Edit single products'},
  {name: 'categories',         module: 'View list categories'},
  {name: 'archive-categories', module: 'Restore categories'},
  {name: 'categories-create',  module: 'Add categories'},
  {name: 'categories-update',  module: 'View Edit single categories'},
  {name: 'inquiry-form-setting',    module: 'Products'},
  {name: 'list-testimonials',       module: 'View list testimonials'},
  {name: 'archive-list-testimonials', module: 'Restore testimonials'},
  {name: 'list-testimonials-create',module: 'Add testimonials'},
  {name: 'list-testimonials-update',module: 'View Edit single testimonials'},
  {name: 'list-testimonials-view',  module: 'View Edit single testimonials'},
  {name: 'all-pages',              module: 'View list pages'},
  {name: 'all-pages-create',       module: 'Add pages'},
  {name: 'archive-all-pages',      module: 'Restore pages'},
  {name: 'all-pages-update',       module: 'View Edit single pages'},
  {name: 'all-pages-view',         module: 'View Edit single pages'},
  {name: 'all-pages-footer',         module: 'View Footer'},
  {name: 'all-pages-footer-update',  module: 'Edit Footer'},
  {name: 'page-content',            module: 'View Navigation'},
  {name: 'top-navigation',          module: 'View Navigation'},
  {name: 'NotFound',                module: 'error'},
  {name: 'PagePermission',          module: 'error'},
  {name: 'file-managent',           module: 'View list file manager'},
  {name: 'all-posts',               module: 'View list blogs'},
  {name: 'all-create-post',         module: 'Add blogs'},
  {name: 'all-update-post',         module: 'View Edit single blogs'},
  {name: 'all-blog-categories',     module: 'View list blog category'},
  {name: 'create-categories',     module: 'Add blog category'},
  {name: 'update-categories',     module: 'View Edit single blog category'},
  {name: 'all-blog-tags',     module: 'View list blog tag'},
  {name: 'create-tags',     module: 'Add blog tag'},
  {name: 'update-tags',     module: 'View Edit single blog tag'}
]; 

const router = createRouter({
  history: baseHistory,
  routes: routes,
});

router.beforeEach((to, from, next) => {
  document.title = meta[to.name] ? meta[to.name].title : ''; //Set page Meta

  const authToken = getItem('access_token');
  const userProfile = getItem('user_profile')
  const isAuthenticated = authToken ? true : false;

  const routeRequiresAuth = isLogin.some(route => route.name === to.name);

  setMeta(to, from);
  if (isAuthenticated) {
    if (isAuth.includes(to.name)) {
      next('/'); // Redirect to home if already logged in
    } else {
      const matchedRoute = findRouteModule(to.name);
      if(checkPermission(matchedRoute.module) || userProfile.role[0].name === 'Super Admin' || matchedRoute.module === 'error' || matchedRoute.module === 'dashboard') {
        next();
      } else {
        next('/error-401');
      }
    }
  } else if (!isAuthenticated) {
    if (routeRequiresAuth) {
      next('/auth/login'); 
    } else {
      next();
    }
  }
});

const findRouteModule = (routeName) => {
  // Use Array.prototype.find() to search for the route by name
  const route = isLogin.find((item) => item.name === routeName);

  if (route) {
    // If a match is found, return the name and module
    return { name: route.name, module: route.module };
  } else {
    // If no match is found, return undefined or a default value
    return undefined;
  }
};

const checkPermission = (permission) => {
  const userPermission = getItem('user_permission')
  return userPermission.includes(permission)
}

export default router;
